import { combineReducers, configureStore } from '@reduxjs/toolkit';

import appReducer from './reducers/appSlice';
import banquetMenuReducer from './reducers/banquetMenuSlice';
import buffetMenuReducer from './reducers/buffetMenuSlice';
import childrenMenuReducer from './reducers/childrenMenuSlice';
import drinkMenuReducer from './reducers/drinkMenuSlice';
import expressMenuReducer from './reducers/expressMenuSlice';
import { menuApi } from '../services/menuService';
import pizzaMenuReducer from './reducers/pizzaMenuSlice';
import snakesForBeerMenuReducer from './reducers/snakesForBeerMenuSlice';
import vegetarianMenuSliceReducer from './reducers/vegetarianMenuSlice';

const rootReducer = combineReducers({
  appReducer,
  banquetMenuReducer,
  buffetMenuReducer,
  expressMenuReducer,
  childrenMenuReducer,
  drinkMenuReducer,
  pizzaMenuReducer,
  snakesForBeerMenuReducer,
  vegetarianMenuSliceReducer,
  [menuApi.reducerPath]: menuApi.reducer,
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(menuApi.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStoreType = ReturnType<typeof setupStore>;
export type AppDispatch = AppStoreType['dispatch'];
