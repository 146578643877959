import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { IGroupMenu } from '../../models/IGroupMenu';
import { IMenu } from '../../models/IMenu';
import { MenuState } from '../../models/MenuState';

const initialState: MenuState = {
  title: '',
  content: [{ id: '', nameGroup: '', content: [], status: false }],
  status: false,
};

export const vegetarianMenuSlice = createSlice({
  name: 'vegetarianMenu',
  initialState,
  reducers: {
    titleFetching(state: MenuState, action: PayloadAction<string>) {
      state.title = action.payload;
    },
    statusFetching(state: MenuState, action: PayloadAction<boolean>) {
      state.status = action.payload;
    },
    vegetarianMenuNameGroupFetchingSuccess(
      state: MenuState,
      action: PayloadAction<IGroupMenu[]>
    ) {
      state.content = action.payload;
    },
    vegetarianMenuFetchingSuccess(
      state: MenuState,
      action: PayloadAction<IMenu[]>
    ) {
      for (let i = 0; i < state.content.length; i++) {
        const result = action.payload.filter(
          (item) => item.parent_item_id === state.content[i].id
        );
        state.content[i].content = result;
      }
    },
  },
});

export default vegetarianMenuSlice.reducer;
