import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/style/_iconsfont.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { HashRouter } from 'react-router-dom';
import { Routers } from '../../route/Routers';

function App() {
  const handleScroll = (event: any) => {};
  return (
    <div className="App" onScroll={handleScroll}>
      <HashRouter>
        <Routers />
      </HashRouter>
    </div>
  );
}

export default App;
