import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

export const menuApi = createApi({
  reducerPath: 'menuApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://ts.inetwork.by/api',
  }),
  endpoints: (build) => ({
    fetchMainMenu: build.query({
      query: (entity_id: number = 51, parent_item_id?: number) => ({
        url: 'rest.php',
        params: {
          key: 'NTZ6sj5FuGSSYhm3IuT1eVeXuxrVVbdfzFEKNvSx',
          username: 'admin7',
          password: '12345678',
          action: 'select',
          entity_id,
          parent_item_id,
        },
      }),
    }),
    fetchBanquetMenu: build.query({
      query: (entity_id: number = 52, parent_item_id?: number) => ({
        url: 'rest.php',
        params: {
          key: 'NTZ6sj5FuGSSYhm3IuT1eVeXuxrVVbdfzFEKNvSx',
          username: 'admin7',
          password: '12345678',
          action: 'select',
          entity_id,
          parent_item_id,
        },
      }),
    }),
  }),
});
